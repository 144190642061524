.breadcrumb{
	@include set-vw('margin-bottom', 40px);
	display: none;

	a{
		color:$grey-light;
		text-decoration: none;
	}

	.bread-item{
		@include responsive-font(get-vw(20px), get-vw(24px), 16px, 20px, 24px);
		transition:.3s all $out-cubic;

		&.current{
			color:$yellow;
		}

		&:hover{
			color:$yellow;
		}
	}

	.separator{
		display: inline-block;
		width: 12px;
		height: 20px;
		margin: 0 10px;
		top: 2px;
	}

	.separator--yellow{
		background:url(#{$svg-dir}arrow-right.svg) no-repeat;
	}

	.separator--grey{
		background:url(#{$svg-dir}arrow-right-grey.svg) no-repeat;
	}

	.single &{
		padding-right: 220px;
	}

	@media #{md("small")} {
		display: block;
	}
}