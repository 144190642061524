.home{
	@media #{md("small", "max")}{
		p{
			margin-bottom: 0;
		}
		h2 + p{
			margin-bottom: 30px;
		}
		
	}
}


.slide{
	background-size:cover;
	background-position:center center;
}

.banner-home{
    margin-bottom: 60px;

	video{
		width: 100%;
		height:auto;
		display: block;
	}

	@media #{md("small", "max")}{
		margin-bottom: 20px;
	}
}

.slide:hover{
	.play-button{
		transform:scale(.95, .95);
	}
}

.play-container{
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	z-index:10;
	color: white;
	font-weight: $bold;
	letter-spacing: 5px;
	text-transform: uppercase;

	.play-button{
		width: 70px;
		height: 70px;
		display: block;
		border-radius: 50%;
		border:3px solid white;
		margin: 0 auto 20px auto;
		transition:.3s all $out-cubic;
	}

	i{
		margin: 0 auto;
		display: block;
		width: 25px;
		position: absolute;
		top: 19px;
		left: 22px;

		svg{
			display: block;
			width: 25px;
			height:28px;
		}
	}

	.play-content{
		text-shadow: 5px 2px 5px rgba(0,0,0, .1);
		display: none;
	}

	@media #{md("small")}{
		.play-button{
			width: 150px;
			height: 150px;
			border:6px solid white;
		}

		i{
			width: 70px;
			top: 30px;
			left: 40px;

			svg{
				display: block;
				width: 78px;
				height:79px;
			}
		}

		.play-content{
			display: block;
		}
	}
}

