/*---------------------------------------*\
		Sélection
\*---------------------------------------*/

::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}


/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

*,
*:after,
*:before {
	position: relative;
	box-sizing: inherit;
	outline: none;
}

html {
	box-sizing: border-box;
	font-size: $font-size;

	// Better font rendering for mac
	&.mac {
		@include antialiased;
	}
}

body {
	font-family: $font-family;
	font-size: 1em;
	color: $font-color;
}

main{
	width: 100%;
	// max-width: 1300px;
	margin:0 auto;
}

.main-wrapper{
	// max-width: 1300px;
	width: 100%;
}


.single{
	.main-inner{
		padding-bottom: 0 !important;
	}
}

/*------------------------------------*\
		Sections
\*------------------------------------*/

.section{
	@include set-vw('padding-bottom', 130px);
}

.section--no-spacing-b{
	padding-bottom: 0 !important;
}

.section--cols{
	padding-bottom:0 !important;
	@include set-vw('margin-bottom', 100px);

	@media #{md("small")} {
		min-height: 400px;	
	}

	@media #{md("medium")} {
		min-height: 555px;	
	}
}

/*------------------------------------*\
		Misc Helpers
\*------------------------------------*/

.f-left{ float: left; }
.f-right{ float: right; }

.medium{
	font-weight: $medium;
}

.sans{
	font-family: $sans;
}

.mb-20{margin-bottom: 20px;}
.mb-40{ 
	margin-bottom: 20px;
	@media #{md("medium")} {
		margin-bottom: 40px;
	}
}

.mb-50{
	@include set-vw('margin-bottom', 50px);
}

.mb-60{
	margin-bottom: 30px;
	@media #{md("medium")} {
		margin-bottom: 60px;
	}
}
.mb-90{
	margin-bottom: 0px;
	@media #{md("small")} {
		margin-bottom: 40px;
	}

	@media #{md("medium")} {
		margin-bottom: 90px;
	}
}
.mb-120{
	@include set-vw('margin-bottom', 120px);
}
.mr-20{margin-right:20px;}
.mr-40{margin-right: 40px;}
.pt-20{padding-top: 20px;}
.pt-40{padding-top: 40px; }

.full-pic{
	width: 100%;
	height: auto;
	display: block;
}
.shadow{ box-shadow:$box-shadow; }


/*------------------------------------*\
		Base couleurs
\*------------------------------------*/

.blue{ color:$blue; }
.yellow{ color:$yellow; }

// IMAGE

.abs-pic{
	display: none;

	img{
		position: absolute;
		top: 0;
		width:35.763888889%;
		height: auto;
	}

	&.abs-pic--left{
		img{
			left: 0;	
		}
	}

	&.abs-pic--right{
		img{
			right: 0;
			left: auto;	
		}
	}

	@media #{md("small")} {
		display: block;
	}
}