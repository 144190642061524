/*
Typographie
===========

Règles de base pour tout le site (h1, h2, blockquote, etc).

-->    http://type-scale.com/    <--

Pratique pour générer des rythmes typographiques rapidement
*/


body {
	line-height: 1.45;
}

p,
ol,
blockquote {
	margin-bottom: 1.3em;
}

ul{
	margin-bottom: 0;
}

h1,
h2,
h3,
h4 {
	margin: 0;
	line-height: 1.2;
	font-weight: 700;
}

.type-title-1{
	// @include set-vw('margin-bottom', 30px);
	margin-bottom: 30px;
	@include responsive-font(get-vw(40px), get-vw(56px), 24px, 40px, 56px);
	font-weight: bold;
}

.type-title-2{
	// @include set-vw('margin-bottom', 20px);
	margin-bottom: 15px;
	@include responsive-font(get-vw(30px), get-vw(45px), 22px, 30px, 45px);
	font-weight: bold;
}

.type-title-2--medium{
	font-weight: $medium;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.type-title-3{
	// @include set-vw('margin-bottom', 20px);
	margin-bottom: 20px;
	@include responsive-font(get-vw(25px), get-vw(40px), 16px, 25px, 40px);
	font-weight: bold;
}



.type-normal{
	@include responsive-font(get-vw(28px), get-vw(42px), 18px, 28px, 42px);
}

small {
	font-size: 0.75em;
}

sup { 
    vertical-align: super;
    font-size: 0.75em;
}