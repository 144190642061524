/*------------------------------------*\
		Slider
\*------------------------------------*/

.slider{

	.table{
		max-width: 700px;
	}

	.content--slide-caption{
		color:$blue;
		

		blockquote{
			max-width: 400px;
		}
		p{
			color:$blue;
			max-width: 450px;
		}
	}

	@media #{md("small")}{
		.content--slide-caption{
			blockquote{

				&:before{
					content:'';
					display: block;
					background:url(#{$svg-dir}quote-top.svg) no-repeat;
					width: 50px;
					height:50px;
					opacity: .5;

					position: absolute;
					left:-60px;
					top: -20px;
				}

				&:after{
					content:'';
					display: block;
					background:url(#{$svg-dir}quote-bottom.svg) no-repeat;
					width: 50px;
					height:50px;
					opacity: .5;

					position: absolute;
					right:-60px;
					bottom: -20px;
				}
			}
		}
	}
}

.slider--page{
	@include set-vw('margin-bottom', 120px);

	@media #{md("small")}{

		.content--slide-caption{
			blockquote, p{
				text-shadow:3px 3px 15px rgba(0, 0, 0, .4);
			}
		}
	}

	@media #{md("small" , "max")}{

		.slide{
            padding-bottom: 80px !important;
        }

        .slick-dots{
        	bottom: 90px !important;
        }

        .play-container{
        	top: 35%;
        }

		.content--slide-caption{
			blockquote{
				margin-bottom: 0;
			}

			p{
				font-size: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				br{
					display: none;
				}
			}
		}
		.type-title-1{
			margin-top: 10px;
			margin-bottom: 0 !important;
			font-size: 14px;
			line-height: 20px;
		}
	}
}