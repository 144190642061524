/*------------------------------------*\
		Buttons
\*------------------------------------*/

.btn--readmore{
	text-decoration: none;
	font-weight: bold;
	vertical-align: middle;

	@include responsive-font(get-vw(18px), get-vw(32px), 18px, 18px, 32px);

	&:after{
		content:'';
		display: inline-block;
		background:url(#{$svg-dir}arrow-right.svg) no-repeat;
		width: 15px;
		height: 20px;
		vertical-align: middle;
		margin-left: 10px;
		transition:.2s transform $out-cubic;
	}

	&:hover{
		&:after{
			transform:translateX(5px);
		}	
	}

	.content &{
		color:$yellow;
	}
}

.btn--arrow-container{
	a{
		@extend .btn--readmore;
	}
}

.btn--border{
	background:$yellow;
	color:$blue;
	border-radius:40px;
	display: block;
	text-decoration: none;
	padding: 10px 30px;
	transition:.3s all $out-cubic;

	@include responsive-font(get-vw(16px), get-vw(32px), 14px, 16px, 32px);

	&:hover{
		background:$blue;
		color:$white;
	}

	// @media #{md("small", "max")} {
	// 	padding: 15px 30px;
	// }
}