/*------------------------------------*\
		Header
\*------------------------------------*/

.main-header{
	padding: 30px 0;
	z-index:4;

	.header__logo{
		width: 22%;
		min-width: 180px;
		z-index:6;
		display: block;
	}

	@media #{md("small")} {
		padding: 40px 0;
		.header__logo{
			width: 22%;
		}
	}

	@media #{md("medium")} {
		.header__logo{
			width: 264px;
			height: 62px;
			i, svg{
				width: 100%;
				height: 100%;
			}
		}
	}
}

.mobile-menu-container{
	z-index:10;
}

.mobile-menu{
	display: block;
	height: 35px;
	width: 50px;
	position: absolute;
	right:0;
	top: 5px;

	.line{
		height:2px;
		background:$yellow;
		height: 4px;
		position: absolute;
		width: 100%;
		border-radius:10px;
		transition:.4s all $out-back;


	}

	.line--top{
		top: 0;
		transform-origin: left;
	}
	.line--mid{
		top: 14px;
	}
	.line--bot{
		top: 29px;
		transform-origin: left;
	}

	@media #{md("small")} {
		display: none;
	}
}

.nav-main{
	z-index:4;
	

	&.nav-main--header{
		position: fixed;
		top: 0;
		left:0;
		height: 100%;
		background:white;
		text-align: center;
		padding-top: 150px;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		transform:translateY(-40px);
		transition:.3s all $out-cubic;
		// max-width: 630px;

		li{
			margin-bottom: 50px;
			display: block;
		}
	}
	

	li{
		display: inline-block;

		&:first-child{
			margin-left:0;
		}

		a{
			color:$blue;
			text-decoration: none;
			font-weight: bold;
			display: inline-block;
			font-size:20px;

			&:after{
				content:'';
				display: block;
				height:3px;
				background:$yellow;
				transform:scaleX(0);
				bottom: -4px;
				transition:.3s all $out-cubic;
			}
		}

		&.menu__item--active{
			a:after{
				transform:scaleX(1);
			}
		}
	}
	@media #{md("small")} {
		li{
			z-index:2;

			a{
				@include responsive-font(get-vw(20px), get-vw(22px), 18px, 20px, 22px);
			}

			&:hover{
				a:after{
					transform:scaleX(1);
				}
			}
		}

		&.nav-main--header{
			position: relative;
			top: 0;
			right:0;
			width: auto;
			text-align: right;
			padding-top: 0;
			// left: auto;
			margin-top: 25px;
			opacity: 1;
			visibility: visible;
			transform:translateY(0);


			li{
				margin-bottom: 0;
				margin-left: 2.5rem;
				display: inline-block;
			}
		}
	}
}

.notouch{
	.nav-main li{
		&:hover{
			a:after{
				transform:scaleX(1);
			}
		}
	}
}

// MENU OPEN ON MOBILE

.menu-open{
	.nav-main{
		opacity: 1;
		visibility: visible;
		transform:translateY(0px);
	}

	.mobile-menu{
		.line--mid{
			opacity: 0;
		}
		.line--top{
			transform:rotate(45deg) translateY(-5px);
		}
		.line--bot{
			transform:rotate(-45deg) translateY(4px);
		}
	}
}