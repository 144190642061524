.banner-page{
	text-align: center;
	// @include set-vw('margin-bottom', 40px);
	margin-bottom: 40px;
	@include set-vw('padding-top', 50px);
	@include set-vw('padding-bottom', 50px);
	
	&.bg-blue{
		background:$blue;
		color:$white;
	}

	&.bg-purple{
		background:$purple;
		color:$white;
	}

	&.bg-yellow{
		background:$yellow;
		color:$white;
	}

	.type-title-1{
		@include set-vw('margin-bottom', 0px);
	}
}

.publilist__item{
	display: block;
	border:2px solid $blue;
	border-radius:10px;
	padding-right: 40px;
	padding-left: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	transition:.3s all $out-cubic;
	margin-bottom: 20px;
	text-decoration: none;
	text-align: center;

	@extend .cf;

	&.publilist__item--purple{
		border:2px solid $purple;
	}

	&.publilist__item--yellow{
		border:2px solid $yellow;

		.publilist__item-medium-content{
			margin-bottom: 20px;
		}
	}

	&.publilist__item--bluelight{
		border:2px solid $blue-light;
	}

	.publilist__item-content{
		// text-transform: uppercase;
		color:$blue;
		display: block;
		vertical-align: middle;
		font-weight: $medium;
		letter-spacing: 1px;
		margin-bottom: 20px;
		font-size: 18px;
		margin-top: 14px;
		transition:.3s all $out-cubic;
	}

	.icon-pdf{
		width: 42px;
		height: 42px;
		display: block;
		vertical-align: middle;
		margin:0 auto;

		svg{
			fill:$blue;
		}
	}

	.publilist__item-btn{
		vertical-align: middle;
		display: inline-block;

		&:hover{
			color:$white;
			background:$blue;
		}
	}

	.publilist__item-surtitle{
		@include responsive-font(get-vw(18px), get-vw(32px), 14px, 18px, 32px);
		margin-bottom: 5px;
		letter-spacing: 1px;
	}

	.publilist__item-medium-content{
		@include responsive-font(get-vw(18px), get-vw(32px), 18px, 18px, 32px);
		text-align: left;
		
		p{
			margin-bottom: 0;

			em {
				font-style: italic;
			}
		}

		ul{
			// @include set-vw('margin-bottom', 50px);
			margin-bottom: 30px;
			li:not(.gfield){
				@include responsive-font(get-vw(20px), get-vw(34px), 16px, 20px, 34px);

				&:before{
					content:'';
					display: inline-block;
					@include triangle-right(15px, 17px, $yellow);
					margin-left: 20px;
					margin-right: 20px;
				}

				a{
					color:$blue-light;
					transition:.2s color $out-expo;

					&:hover{
						color:$yellow;
					}
				}
			}
		}
	}

	.publilist__item-title{
		@include responsive-font(get-vw(20px), get-vw(32px), 18px, 20px, 32px);
		// text-transform: uppercase;
		font-weight: $medium;
		letter-spacing: 1px;
	}

	@media #{md("small")} {
		text-align: left;

		.publilist__item-content{
			margin-bottom: 0px;
			font-size: 18px;
			display: inline-block;
		}

		.publilist__item-content--ellipsis{
			width: 60%;
			@include ellipsis;
		}

		.icon-pdf{
			margin-right: 20px;
			display: inline-block;
		}

		.publilist__item-btn{
			float: right;
		}

		.publilist__item-btn--abs{
			position: absolute;
			bottom:0;
			right:0;
		}

		.publilist__item-btn--bl{
			position: relative !important;
			right: 0;
		}

		&.publilist__item--yellow{
			border:2px solid $yellow;

			.publilist__item-btn{
				position: absolute;
				right:0;
			}

			.publilist__item-medium-content{
				margin-bottom: 0;
			}
		}
	}

	@media #{md("small" , "max")}{
		padding-left: 20px;
		padding-right: 20px;
	}

	@media #{md("smaller", "max")} 
	{
		.publilist__item-medium-content{
			ul{
				li:not(.gfield){
					&:before{
						margin-left: 0;
						margin-right: 10px;
						top: 3px;
					}
				}
			}
		}
	}
}

.publilist--future{
	.publilist__item{
		.publilist__item-btn{
			float: none;
		}

		.publilist__item-medium-content{
			p{
				margin-bottom: 30px;
			}
		}
	}
}

.expand-content{
	display: block;

	@media #{md("smaller", "max")}{
		display: none;

		&.expanded{
			display: block;
			margin-top: 20px;
		}
	}
}

.expand{
	display: block;
	cursor:pointer;

	div:first-of-type{
		&:before{
			content:'';
			display: inline-block;
			height:1px;
			background:$yellow;
			width: 30px;
			position: absolute;
			top: 15px;
			margin-left: -15px;
		}
		&:after{
			content:'';
			display: inline-block;
			width:1px;
			background:$yellow;
			height: 30px;
			margin:0 auto;
		}
	}

	@media #{md("smaller")}{
		display: none;
	}
}
	
.seemore{
	text-decoration: none;
	cursor: pointer;
	transition:.3s all $out-cubic;
	@include responsive-font(get-vw(18px), get-vw(34px), 14px, 18px, 34px);


	&:hover{
		color:$blue;
		&:after{
			@include triangle-down(13px, 12px, $blue);
		}
	}

	&:after{
		content:'';
		@include triangle-down(13px, 12px, $yellow);
		display: inline-block;
		margin-left: 20px;
		transition:.3s all $out-cubic;
	}

	&.open{
		&:after{
			@include triangle-up(13px, 12px, $yellow);
		}

		&:hover{
			color:$blue;
			&:after{
				@include triangle-up(13px, 12px, $blue);
			}
		}
	}
}

.publilist--hidden{
	opacity: 0;
	visibility: hidden;
	display: none;
	transition:.3s all $out-cubic;

	&.show{
		display: block;
		opacity: 1;
		visibility: visible;
	}
}

.block-grey{
	background:#eeeeee;
	@include set-vw('padding-top', 120px);
	@include set-vw('padding-bottom', 120px);
}

.actions{
	margin-bottom: 0;
	a{
		display: inline-block;
		margin-right: 15px;

		&:hover{
			svg{
				fill:$blue;
			}
		}
	}

	@media #{md("small")} {
		position: absolute;
		top: 0;
		right:0;

		a{
			margin-left: 15px;
			margin-right: 0;			
		}
	}
}

.yt-container{
	@include set-vw('margin-bottom', 120px);

	iframe{
		width: 100%;
		@include set-vw('height', 700px);
	}
}