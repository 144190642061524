/*------------------------------------*\
		Form
\*------------------------------------*/

$error_color: #d62e2e;

.gform_heading{
	margin-bottom: 40px;
}

.form-yellow{
	.ginput_container{
		input:not([type='file']), textarea{
			border:2px solid $yellow;
		}

		input[aria-invalid='true'], textarea[aria-invalid='true'] {
		  border: 2px solid $error_color;
		}
	}
}

.gform_wrapper{
	margin:0 auto;
	display: block !important;
	max-width: 800px;
}

.gform_fields{
	margin-bottom: 40px !important;
}

.gfield{
	label{
		@include responsive-font(get-vw(20px), get-vw(22px), 18px, 20px, 22px);
		margin-bottom: 10px;
		display: block;
		font-weight: $bold;
	}

	@media #{md("smaller", "max")}{
		margin-bottom: 30px;
		label{
			margin-bottom: 5px;
		}
	}
}

.gp_readonly_field{
	pointer-events:none;
	&:before{
		content:'';
		display: block;
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		height: 100%;
		background: white;
		z-index: 10;
		opacity: 0
	}
	input{
		background:#eeeeee;
		pointer-events:none;
	}
}

.ginput_container{
	width: 100%;
	@include set-vw('margin-bottom', 40px);

	input, textarea{
		border:2px solid $purple;
		border-radius:10px;
		width: 100%;
		padding: 10px 20px;
		@include responsive-font(get-vw(20px), get-vw(34px), 18px, 20px, 34px);
		font-weight: 300;
	}
	input{
		height: 54px;
		line-height: 24px;
	}

	input[aria-invalid='true'], textarea[aria-invalid='true'],  {
	  border: 2px solid $error_color;
	}

	[type='file']{
		font-size: 16px;
	}
}

.gfield_error .ginput_container{
	.upload-show{
		border: 2px solid $error_color;
	}
}

.ginput_container_fileupload{

	.screen-reader-text{
		display: none;
	}

	.btn--border{
		@include responsive-font(get-vw(16px), get-vw(32px), 18px, 16px, 32px);
		z-index:-1;
		display: inline-block;
		margin-left: 0;
		margin-top: 10px;
	}

	input[type="file"]{
		opacity: 0;
		position: absolute;
		left:0;
		bottom: 0;
		width: 250px;
		cursor: pointer;
	}
}

.gform_footer{
	input[type="submit"]{
		@extend .btn--border;
		@include responsive-font(get-vw(16px), get-vw(32px), 18px, 16px, 32px);
		text-align: center;
		margin:0 auto;

		&:hover{
			background:$blue;
			color:$white;
		}
	}
}

.validation_error{
	border:2px solid $error_color;
	padding: 20px;
	border-radius:20px;
	@include responsive-font(get-vw(18px), get-vw(22px), 14px, 18px, 22px);
	margin-bottom: 40px;
}

.validation_message{
	position: absolute;
	left:0;
	bottom: -33px;
	font-size:14px;
	color: $error_color;

	&.instruction{
		display: none;
	}

	@media #{md("smaller", "max")}{
		bottom: -28px;
	}
}

.gfield_error:after{
	content:'X';
	font-family: Arial;
	display: block;
	color:$error_color;
	position: absolute;

	top: 42px;
	right: 20px;

	.upload-show{
		border:2px solid $error_color !important;
	}

	@media #{md("smaller", "max")}{
		top: 45px;
	}
}