/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/

// Styles pour sprite PNG
$sprite-name: "sprite";
$sprite-size: 512px 512px;

%sprite-png {
	background-image: url("#{$img-dir}#{$sprite-name}.png");
	background-repeat: no-repeat;

	// And for the retina!
	@media #{md("retina")} {
		background-image: url("#{$img-dir}#{$sprite-name}@2x.png");
		background-size: $sprite-size;
	}
}


.icon_scrolldown{
	&,svg{
		width: 9px;
		height: 31px;
	}
}


.icon_logo{
	&, svg{
		display: block;
		// width: 95%;
		height: auto;
	}
}

.icon_printer, .icon_mail, .icon_pdf{
	&, svg{
		width: 25px;
		height:25px;
	}

	@media #{md("small")} {
		&, svg{
			width: 37px;
			height:37px;
		}
	}
}

// .icon_play{
// 	&, svg{
// 		display: block;
// 		width: 100px;
// 	}
// }