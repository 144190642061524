/*------------------------------------*\
		Footer
\*------------------------------------*/

.main-footer{
	padding: 60px 0;
	background:$blue;
	color:$white;

	text-align: center;

	p, a, span{
		color:$white;
		text-decoration: none;
		@include responsive-font(get-vw(18px), get-vw(20px), 14px, 18px, 20px);
		font-weight:$regular;
	}


	p > a{
		display: inline-block;
		&:after{
			content:'';
			display: block;
			height:3px;
			background:$white;
			transform:scaleX(0);
			bottom: -4px;
			transition:.3s all $out-cubic;
		}
	}

	.tar{
		text-align: center;
	}

	a:hover{
		&:after{
			transform:scaleX(1);	
		}
	}

	@media #{md("small")} {
		text-align: left;
		.tar{
			text-align: right;
		}
	}
	
}

.nav-main--footer{
	margin-top: 5px;
	margin-bottom: 30px;
	text-align: center;

	li{
		margin-left: 1.5rem;
		margin-bottom: 10px;

		a{
			color:$white;
			@include responsive-font(get-vw(18px), get-vw(20px), 14px, 18px, 20px);
		}
	}

	@media #{md("small")} {
		text-align: right;

		li{
			margin-bottom: 0;
			
			a{
				@include responsive-font(get-vw(18px), get-vw(20px), 14px, 18px, 20px);
			}
		}
	}

	@media #{md("small", "max")} {
		.menu{
			width: 100%;
			text-align: center;

			li{
				margin: 0 5px;
				display: inline-block;
				margin-bottom: 10px;
			}
		}
	}
}

.scrolltop{
	display: none;
	width: 51px;
	height: 51px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fc76a9+0,fa6688+30,f96474+44,f76747+73,f56e25+93,f57113+100 */
	background: #fc76a9; /* Old browsers */
	background: -moz-linear-gradient(45deg, #fc76a9 0%, #fa6688 30%, #f96474 44%, #f76747 73%, #f56e25 93%, #f57113 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #fc76a9 0%,#fa6688 30%,#f96474 44%,#f76747 73%,#f56e25 93%,#f57113 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #fc76a9 0%,#fa6688 30%,#f96474 44%,#f76747 73%,#f56e25 93%,#f57113 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc76a9', endColorstr='#f57113',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	position: absolute;
	right: 0;
	bottom: 0;

	cursor:pointer;

	&:before{
		content:'';
		display: block;
		@include triangle-up(20px, 15px, #fff);
		margin:0 auto;
		padding-top: 17px;
	}

	&.fixed{
		position: fixed;
		z-index:9999;
	}

	@media #{md("small")} {
		display: block;
		width: 71px;
		height: 71px;
		&:before{
			@include triangle-up(25px, 20px, #fff);
			padding-top: 25px;
		}
	}
}