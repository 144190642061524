/*------------------------------------*/
/*		Fontface declaration
/*------------------------------------*/

@font-face {
    font-family:"Neue";
    src:url("../fonts/neue-haas-grotesk-regular.woff") format("woff"),
        url("../fonts/neue-haas-grotesk-regular.otf") format("opentype");
    font-style:normal;
    font-weight:400;
}

@font-face {
    font-family:"Neue";
    src:url("../fonts/neue-haas-grotesk-bold.woff") format("woff"),
    	url("../fonts/neue-haas-grotesk-bold.otf") format("opentype");
    font-style:normal;
    font-weight:bold;
}


@font-face {
    font-family:"Neue";
    src:url("../fonts/neue-haas-grotesk-medium.woff") format("woff"),
        url("../fonts/neue-haas-grotesk-medium.otf") format("opentype");
    font-style:normal;
    font-weight:500;
}