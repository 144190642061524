/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    // @include set-vw('height', 720px);
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.slider{

    .slide{
         .slide__caption{
            position: absolute;
            width: 100%;

            .container, .row, .col-xs-12{
                height:100%;
            }

            .cell{
                vertical-align: middle;
            }

            .slide__caption-content{
                margin-bottom: 0;
                @include responsive-font(get-vw(50px), get-vw(66px), 24px, 50px, 66px);
                color:$blue;
                font-weight: bold;
            }
        }
    }
   
    .slick-dots{
        width: 100%;
        position: absolute;
        margin-bottom: 0;
        text-align: center;

        li{
            display: inline;
            margin:0 10px;

            button{
                width: 15px;
                height: 15px;
                overflow: hidden;
                border-radius:50%;
                background: $white;
                opacity: .5;
                text-indent: -9999px;   
                transition: all 0.4s $out-expo;
            }

            &:not(.slick-active):hover{
                button{
                    transform:translateY(-2px);
                }
            }

            &.slick-active{
                button{
                    opacity: 1;
                }
            }
        }
    }


    @media #{md("small")}{
        .slide{
            .slide__caption{
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left:0;
            }
        }

        .slick-dots{
            bottom: 50px;
        }
    }

    @media #{md("small", "max")}{
        .slide{
            padding-bottom: 40px;
        }
        
        .slick-dots{
            bottom: 50px;

            li{
                margin: 0 5px;
            }
        }
        .slide{
            .slide__caption{
                .slide__caption-content{
                    font-size: 16px;
                }
            }       
        }
    }
}