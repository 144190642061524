$contentColor : #5f5f5f;

.content{

	color:$contentColor;
	@include responsive-font(get-vw(20px), get-vw(34px), 16px, 20px, 34px);

	
	h1{
		@extend .type-title-1;
	}

	h2{
		@extend .type-title-2;
	}

	h3{
		@extend .type-title-3;
	}

	p{
		@include responsive-font(get-vw(20px), get-vw(34px), 16px, 20px, 34px);
		// @include set-vw('margin-bottom', 30px);
		margin-bottom: 30px;

		strong{
			font-weight:600;
		}

		a{
			color:$blue-light;
			transition:.2s color $out-expo;

			&:hover{
				color:$yellow;
			}
		}

		em{
			font-style: italic;
		}
	}

	ul{
		// @include set-vw('margin-bottom', 50px);
		margin-bottom: 30px;
		li:not(.gfield){
			@include responsive-font(get-vw(20px), get-vw(34px), 16px, 20px, 34px);

			&:before{
				content:'';
				display: inline-block;
				@include triangle-right(15px, 17px, $yellow);
				margin-left: 20px;
				margin-right: 20px;
			}

			a{
				color:$blue-light;
				transition:.2s color $out-expo;

				&:hover{
					color:$yellow;
				}
			}
		}
	}

	.inline-list{
		@include set-vw('margin-bottom', 50px);

		a{
			text-transform: uppercase;
			text-decoration: none;
			color: $blue-light;
			@include responsive-font(get-vw(20px), get-vw(34px), 18px, 20px, 34px);
			transition:.3s all $out-cubic;

			&:after{
				content:'';
				display: block;
				height:3px;
				background:$yellow;
				transform:scale3d(0, 1, 1);
				bottom: -4px;
				transition:.3s all $out-cubic;
				position: absolute;
				bottom:0;
				left:0;
				width: 100%;
			}

			&:hover{
				&:after{
					transform:scale3d(1, 1, 1);
				}
			}
		}
	}

	hr{
		border:none;

		@include set-vw('margin-top', 50px);
		@include set-vw('margin-bottom', 50px);
	}

	hr{
		@include clearfix();
	}

	.alignleft{
		max-width: 300px;
		float: left;
		height:auto;
		@include set-vw('padding-right', 50px);
	}

	.alignright{
		max-width: 300px;
		float: right;
		height:auto;
		@include set-vw('padding-left', 50px);
	}

	.aligncenter{
		text-align: center;
		display: block;
		margin:0 auto;
		max-width: 100%;
	}

	&.content--intro{
		@include set-vw('margin-bottom', 40px);

		p{
			@include responsive-font(get-vw(22px), get-vw(34px), 18px, 22px, 34px);
		}
	}

	@media #{md("large")}{
		.alignleft, .alignright{
			max-width: 456px;
		}
	}

	@media #{md("smaller", "max")} 
	{
		.alignleft, .alignright{
			display: none;
			float: none;
			max-width: 100%;
			padding: 0;
		}

		ul{
			li:not(.gfield){
				&:before{
					margin-left: 0;
					margin-right: 10px;
					top: 3px;
				}
			}
		}
	}
}

.content--pt{
	padding-top: 70px;
}